export const initHeader = () => {
  const header = document.body.querySelector('header'),
    nav = document.body.querySelector('.header__navigation'),
    mobileMenu = document.querySelector('.header__mobile-menu'),
    menuLinks = document.querySelectorAll('.header__menu-item-link,.header__order-btn'),
    body = document.querySelector('body');

  mobileMenu.addEventListener('click', () => {
    mobileMenu.classList.toggle('active');
    nav.classList.toggle('active');
    header.classList.toggle('active');
  });

  menuLinks.forEach((link) => {
    link.addEventListener('click', () => {
      nav.classList.remove('active');
      mobileMenu.classList.remove('active');
    });
  });

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > 0) {
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
    }
  });
};
import { initSlider } from './libs/slider';
import { initHeader } from './_header';

(function () {
  if (typeof NodeList !== 'undefined' && NodeList.prototype && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  try {
    initHeader();
    initSlider();
  } catch (err) {
    console.log(err);
  }
  
})();
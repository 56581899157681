import $ from 'jquery';
import 'slick-carousel/slick/slick';

const howWorkSlider = $('.work-slider');
const howWorkSlide = $('.work-slide');

export const initSlider = () => {
  howWorkSlide.css ({'display': 'flex'});
  $(() => {
    howWorkSlider.slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
    });
  });
};
